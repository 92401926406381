<template>
  <v-row>
    <input-v
      v-for="(field, key) in fields"
      class="pl-4"
      :colSize="field.colSize"
      :editable="field.editable"
      :key="key"
      :label="field"
      :opts="{}"
      :valid.sync="valid[field.key]"
      :value="value[field.key]"
      @input="inputHandler(field.key, $event)"
    ></input-v>
  </v-row>
</template>

<script>
export default {
  components: {
    "input-v": () => import("@/components/input-v.vue"),
  },
  computed: {
    fields: function () {
      const projetos = Array.isArray(this.projetos) ? this.projetos : [];
      const hasCompetencia = !!this.value.competencia;
      const hasProjetos = projetos.length > 0;

      return [
        {
          key: "competencia",
          name: "Competência",
          type: this.$fieldTypes.MONTH,
          rules: [{ rule: "required" }],
          colSize: 3,
        },
        {
          key: "projetoId",
          name: "Projeto",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { toEdit: projetos, key: "id", name: "titulo" },
          rules: [{ rule: "required" }],
          editable: hasCompetencia && hasProjetos,
          placeholder: hasCompetencia ? 'Selecione um projeto' : 'Escolha uma competência antes de um projeto',
          colSize: 9,
        },
        {
          key: "justificativa",
          name: "Justificativa",
          type: this.$fieldTypes.TEXTAREA,
          colSize: 12,
        },
      ];
    },
  },
  created: function () {
    this.$emit('update:valid', this.generateUpdateValid());
  },
  data: function () {
    return {
      valid: {
        competencia: null,
        projetoId: null,
        justificativa: '',
      }
    };
  },
  methods: {
    generateUpdateValid: function () {
      const self = this;
      return () => Object.entries(self.valid).reduce((acc, [key, fn]) => {
        if (fn && fn()) {
          return acc;
        }

        const { name } = self.fields.find((field) => field.key === key);
        return [ ...acc, name ];
      }, []);
    },
    inputHandler: function (key, value) {
      this.$emit('input', {
        ...this.value,
        [key]: value,
      });

      if (key === 'competencia') {
        this.$emit('competencia', value);
      }
    },
  },
  model: {
    event: 'input',
    prop: 'value',
  },
  name: 'depreciation-form',
  props: {
    projetos: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => ({
        competencia: null,
        projetoId: null,
        justificativa: null,
      }),
    }
  },
}
</script>
